import { getAuthUser } from '#imports';

export default defineNuxtRouteMiddleware((from, to) => {
  const authUser = getAuthUser();
  logger().debug('[middleware] not-verified', { from: from.fullPath, userId: authUser?.id, emailVerifiedAt: authUser?.emailVerifiedAt });

  if (authUser?.emailVerifiedAt) {
    const target = from.path.indexOf('/auth/') !== 0
      ? from.fullPath
      : '/projects';
    logger().debug('[middleware] not-verified', { target });
    return navigateTo(target);
  }
});
